export type UserJson = {
    uid?: number | string,
    email: string
};

export class User {
    uid: number;
    email: string;

    constructor(uid: number = -1, email: string) {
        this.uid = uid;
        this.email = email;
    }

    static fromJson(json: UserJson): User {
        return new User(
            Number(json.uid) || -1, // Convert uid to number, use -1 if undefined or NaN
            json.email
        );
    }

    static fromList(jsonList: UserJson[] | null): User[] {
        if (!jsonList) return [];
        return jsonList.map(json => User.fromJson(json));
    }

    toJson(): UserJson {
        return {
            uid: this.uid,
            email: this.email
        };
    }

    toString(): string {
        return `UID: ${this.uid}, \nEmail: ${this.email}`;
    }
}
