import React, {useContext, useState} from 'react';
import tum_logo from '../assets/tum_logo.png';
import catena_logo from '../assets/catena_logo.png';
import {AuthContext} from "../context/AuthTokenContext";
import {fetchLoginPassword, postRegister} from "../utils/RequestApi";

type RegisterProps = {
    navigateTo: (page: 'main' | 'login' | 'register' | 'home') => void;
};
const Register: React.FC<RegisterProps> = ({ navigateTo }) => {
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const { setToken } = useContext(AuthContext);

    const handleRegister = async () => {
        if(password !== confirmPassword) {
            alert("Passwords do not match!");
            return;
        }
        try {
            const isLoggedIn = await postRegister(email.toLowerCase(), password, setToken);
            if (isLoggedIn) {
                navigateTo('login');
                alert("Registration successful! Please log in.");
            }
        } catch (error) {
            console.error("Login failed:", error);
        }
    };

    return (
        <div className="login-container">
            <div className="login-logo-container">
                <div className="login-logo-row">
                    <img src={tum_logo} alt="tum" className="login-logo-big"/>
                </div>
                <div className="login-logo-row">
                    <span className="digital-contracts">Digital Contracts</span>
                </div>
                <div className="login-logo-row">
                    <span className="powered-by">powered by</span>
                </div>
                <div className="login-logo-row">
                    <img src={catena_logo} alt="catena" className="login-logo"/>
                    <span className="catena">CATENA</span>
                </div>
            </div>
            <div className="login-logo-row">
                <input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
            </div>
            <div className="login-logo-row">
                <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
            </div>
            <div className="login-logo-row">
                <input
                    type="password"
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                />
            </div>
            <div className="login-logo-row">
                <button className="login-button" onClick={handleRegister}>Register</button>
            </div>
            <div className="register-link-container">
                <span className="register-link" onClick={() => navigateTo('login')}>Already have an account? Login here.</span>
            </div>
        </div>
    );
}
export default Register;
