import React, {useEffect, useState, useRef, useContext} from 'react';
import {
    getDocuments,
    bookDocumentTransaction,
    getOwnUserDetails,
    bookEncryptedDocumentTransaction, fetchLoginCrypt
} from '../utils/RequestApi';
import { Document } from '../types/Document';
import { Profile } from '../types/Profile';
import { AuthContext } from '../context/AuthTokenContext';
import { DocumentTile } from './DocumentTile';
import { FiUsers, FiUser, FiLogOut } from 'react-icons/fi';
import logo from '../assets/tum_logo.png';
import {UserList} from './UserList';
import {UserProfile} from './UserProfile';
import {CryptID} from "../utils/CryptId";
import { FaPlus } from 'react-icons/fa';

interface HomeProps {
    navigateTo: (page: 'main' | 'login' | 'register' | 'home') => void
}
const Home: React.FC<HomeProps> = ({ navigateTo }) => {
    const { token, setToken } = useContext(AuthContext);
    const [searchTerm, setSearchTerm] = useState("");
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [isUploading, setIsUploading] = useState(false);
    const [isUserListOpen, setIsUserListOpen] = useState(false);
    const [cryptID, setCryptID] = useState<CryptID | null>(null);
    const [isUserProfileOpen, setIsUserProfileOpen] = useState(false);
    const [userProfile, setUserProfile] = useState<Profile | null>(null);
    // Documents loading
    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const [documents, setDocuments] = useState<Document[]>([]);
    const initialLoadCount = 40;
    const [offset, setOffset] = useState(initialLoadCount);
    const limit = 40;
    const [isLoading, setIsLoading] = useState(false);

    const loadMoreDocuments = async () => {
        if (isLoading) {
            return;
        }
        setIsLoading(true);
        try {
            if (token) {
                const newDocuments = await getDocuments(offset, limit, token);
                setDocuments(prevDocs => {
                    const uniqueDocIds = new Set(prevDocs.map(doc => doc.did));
                    const uniqueNewDocs = newDocuments.filter(doc => !uniqueDocIds.has(doc.did));
                    return [...prevDocs, ...uniqueNewDocs];
                });
                const newOffset = offset + newDocuments.length;
                setOffset(newOffset);
            }
        } catch (error) {
            console.error('Failed to fetch more documents:', error);
        }
        setIsLoading(false);
    };

    const handleScroll = () => {
        if (scrollContainerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = scrollContainerRef.current;
            if (scrollTop + clientHeight >= scrollHeight - 10) {
                loadMoreDocuments();
            }
        }
    };

    useEffect(() => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (scrollContainerRef.current) {
                scrollContainerRef.current.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    useEffect(() => {
        const fetchInitialDocuments = async () => {
            if(!token) {
                return;
            }
            try {
                const fetchedDocs = await getDocuments(0, initialLoadCount, token);
                setDocuments(fetchedDocs);
            } catch (error) {
                console.error('Failed to fetch documents:', error);
            }
        };
        fetchInitialDocuments();
    }, [token]);

    const openUserList = () => {
        setIsUserListOpen(true);
    };

    const closeUserList = () => {
        setIsUserListOpen(false);
    };

    const openUserProfile = () => {
        fetchUserProfile();
        setIsUserProfileOpen(true);
    };

    const closeUserProfile = () => {
        setIsUserProfileOpen(false);
    };

    const fetchUserProfile = async () => {
        if (!token) return;

        try {
            const profile = await getOwnUserDetails(token);
            setUserProfile(profile);
        } catch (error) {
            console.error(error);
            alert('Failed to fetch user profile.');
        }
    };

    const handleLogout = () => {
        console.log("Logging out, clearing states...");

        localStorage.removeItem('private5.pem');
        localStorage.removeItem('public5.pem');
        setCryptID(null);
        setToken('');
        window.location.reload();
    };

    const handleClick = () => {
        fileInputRef.current?.click();
    };

    const handleDocumentDelete = (did: number) => {
        setDocuments(prevDocs => prevDocs.filter(doc => doc.did !== did));
    };

    const fetchLatestDocument = async () => {
        if(!token) return;
        try {
            const latestDocuments = await getDocuments(0, 1, token);
            if (latestDocuments && latestDocuments.length > 0) {
                setDocuments(prevDocs => [latestDocuments[0], ...prevDocs]);
            }
        } catch (error) {
            console.error('Error fetching the latest document:', error);
            alert('An error occurred while fetching the latest document.');
        }
    };

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if(!token) return;
        if (file) {
            setIsUploading(true);
            const fileExtension = (file.name.split('.').pop() ?? '').toLowerCase();
            try {
                let success;
                if (fileExtension === 'pdf') {
                    success = await bookEncryptedDocumentTransaction(file, file.name, token);
                } else {
                    success = await bookDocumentTransaction(file, file.name, token);
                }

                if (success) {
                    alert('Document uploaded successfully.');
                    fetchLatestDocument();
                } else {
                    alert('Failed to upload document.');
                }
            } catch (error) {
                console.error('Error uploading document:', error);
                alert('An error occurred while uploading the document.');
            }
        }
        setIsUploading(false);
    };

    return (
        <div className="app-container">
            <div className="sidebar">
                <div className="sidebar-logo">
                    <img src={logo} alt="C-Chain Logo" width="50" />
                </div>
                <div className="sidebar-title">C-Chain for Documents</div>
                <div className="sidebar-divider"></div>
                <button className="sidebar-button" onClick={openUserList}>
                    <FiUsers className="sidebar-icon" />
                    User List
                </button>
                {isUserListOpen && (
                    <>
                        <div className="user-overlay" onClick={closeUserList}></div>
                        <UserList onClose={closeUserList} />
                    </>
                )}
                <button className="sidebar-button" onClick={openUserProfile}>
                    <FiUser className="sidebar-icon" />
                    My Profile
                </button>
                {isUserProfileOpen && (
                    <>
                        <div className="user-overlay" onClick={closeUserProfile}></div>
                        <UserProfile  onClose={closeUserProfile} profile={userProfile} isEditable={true}/>
                    </>
                )}
                <div className="sidebar-footer">
                    <div className="sidebar-divider"></div>
                    <button className="sidebar-button sidebar-button-logout" onClick={handleLogout}>
                        <FiLogOut className="sidebar-icon" />
                        Logout
                    </button>
                </div>
            </div>
        <div ref={scrollContainerRef} className="list-container">
            {isUploading && (
                <div className="overlay" style={{cursor: "wait"}}>
                    <div className="spinner"></div>
                </div>
            )}
            <div className="documents-header">
                <h2>Documents</h2>
                <input
                    type="text"
                    placeholder="Search documents..."
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                    className="search-bar"
                />
            </div>
                <div className="documents-grid">
                    {documents.map((document) => (
                        <DocumentTile key={document.did} document={document}  onDelete={handleDocumentDelete}/>
                    ))}
                </div>
            <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileChange}
            />
            <button className="fab" onClick={handleClick} title="Add New Document">
                <FaPlus />
            </button>
        </div>
        </div>
    );
};

export default Home;
