import {CryptID} from "./CryptId";
import utf8 from 'crypto-js/enc-utf8';
import {Document} from "../types/Document";
import {User} from "../types/User";
import sha256 from 'crypto-js/sha256';
import CryptoJS from 'crypto-js';
import base64 from 'crypto-js/enc-base64';
import {Profile} from "../types/Profile";


const apiUrl = "https://dbmvm1.cit.tum.de/";

export async function fetchLoginCrypt(id: CryptID | null): Promise<string | null> {
    console.log("LoginCrypt");
    try {
        if (!id
        ) {
            return null;
        }
        const response = await fetch(`${apiUrl}user/auth`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                cryptID: id.toJson(),
            })
        });

        if (response.status === 200) {
            const json = await response.json();
            console.log(json);
            if (json.error) {
                throw new Error(`Status Error: ${json.message}`);
            }
            return json["token"];
        } else {
            return null;
        }
    } catch (error) {
        if (error instanceof TypeError) {
            throw new Error("Server Error.");
        }
        console.log(error);
        throw new Error("Timeout.");
    }
}

export async function fetchLoginPassword(email: string, password1: string, id: CryptID | null, onTokenReceived: (token: string) => void): Promise<boolean> {
    console.log("LoginPassword");
    try {
        if (!id
        ) {
            throw new Error("CryptId is not initialized");
        }

        const salt = 'UVocjgjgXg8P7zIsC93kKlRU8sPbTBhsAMFLnLUPDRYFIWAk';
        const saltedPassword = utf8.parse(salt + password1);
        const password = sha256(saltedPassword).toString();

        const response = await fetch(`${apiUrl}user/auth`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                email,
                password,
                cryptID: id.toJson(),
            })
        });


        if (response.status === 200) {
            const json = await response.json();
            console.log(json);
            onTokenReceived(json["token"]);
            return true;
        } else {
            throw new Error("Wrong password or email!");
        }
    } catch (error) {
        if (error instanceof TypeError) {
            throw new Error("Server Error.");
        }
        throw new Error("Timeout.");
    }
}

export async function getDocuments(offset: number, limit: number, token: string): Promise<Document[]> {
    const url = `${apiUrl}user/documents`;
    console.log(token);
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': token,
        'Offset': offset.toString(),
        'Limit': limit.toString()
    });

    const requestOptions: RequestInit = {
        method: 'GET',
        headers: headers,
    };

    try {
        const response = await fetch(url, requestOptions);

        if (response.status === 200) {
            const jsonList = await response.json();
            return Document.fromList(jsonList);
        } else {
            return [];
        }
    } catch (error) {
        console.error(error);
        return [];
    }
}

export async function getDocument(did: number, token: string): Promise<Uint8Array> {
    const url = `${apiUrl}user/document/${did}`;

    const headers = new Headers();
    headers.append('Authorization', token);
    headers.append('Accept', 'application/pdf');

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: headers,
        });

        if (!response.ok) {
            console.log(`[log] Error with status ${response.status}: ${response.statusText}`);
            return new Uint8Array();
        }

        const responseBody = new Uint8Array(await response.arrayBuffer());
        console.log(`[log] Response bytes: ${responseBody}`);
        return responseBody;
    } catch (error) {
        console.log(`[log] Error: ${error}`);
        return new Uint8Array();
    }
}

export async function getDecryptedDocument(did: number, token: string): Promise<Uint8Array> {
    const url = `${apiUrl}user/documents/decrypt/${did}`;

    const headers = new Headers();
    headers.append('Authorization', token);
    headers.append('Accept', 'application/pdf');

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: headers,
        });

        if (!response.ok) {
            console.log(`[log] Error with status ${response.status}: ${response.statusText}`);
            return new Uint8Array();
        }

        const responseBody = new Uint8Array(await response.arrayBuffer());
        console.log(`[log] Response bytes: ${responseBody}`);
        return responseBody;
    } catch (error) {
        console.log(`[log] Error: ${error}`);
        return new Uint8Array();
    }
}

export async function getUsers(token: string): Promise<User[]> {
    try {
        const requestHeaders: HeadersInit = new Headers();
        requestHeaders.set("Content-Type", "application/json");
        requestHeaders.set("Accept", "application/json");
        requestHeaders.set("Authorization", token);

        const response = await fetch(`${apiUrl}users`, {
            method: "GET",
            headers: requestHeaders
        });

        if (response.status === 200) {
            const responseBody = await response.json();
            console.log(responseBody);
            return User.fromList(responseBody);
        } else {
            return [];
        }
    } catch (error) {
        console.error("Error fetching users:", error);
        return [];
    }
}

export async function bookDocumentTransaction(document: File, name: string, token: string): Promise<boolean> {
    try {
        const documentBytes = await new Promise<ArrayBuffer>((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => resolve(e.target!.result as ArrayBuffer);
            reader.onerror = reject;
            reader.readAsArrayBuffer(document);
        });
        const uint8Array = new Uint8Array(documentBytes);
        const numberArray: number[] = Array.from(uint8Array);
        const wordArray = CryptoJS.lib.WordArray.create(numberArray);
        const hash = sha256(wordArray);
        const password = base64.stringify(hash).substring(0, 16);
        const body = {
            name: name,
            password: password,
            document: numberArray
        };

        const response = await fetch(`${apiUrl}user/document`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': token,
            },
            body: JSON.stringify(body)
        });

        return response.status === 200;
    } catch (error) {
        console.error("Error book transaction:", error);
        return false;
    }
}

export async function bookEncryptedDocumentTransaction(document: File, name: string, token: string): Promise<boolean> {
    try {
        const documentBytes = await new Promise<ArrayBuffer>((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => resolve(e.target!.result as ArrayBuffer);
            reader.onerror = reject;
            reader.readAsArrayBuffer(document);
        });
        const uint8Array = new Uint8Array(documentBytes);
        const numberArray: number[] = Array.from(uint8Array);
        const wordArray = CryptoJS.lib.WordArray.create(numberArray);
        const hash = sha256(wordArray);
        const password = base64.stringify(hash).substring(0, 16);
        const body = {
            name: name,
            password: password,
            document: numberArray
        };

        const response = await fetch(`${apiUrl}user/document/encrypt`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': token,
            },
            body: JSON.stringify(body)
        });

        return response.status === 200;
    } catch (error) {
        console.error("Error book transaction:", error);
        return false;
    }
}

export async function getOwnUserDetails(token: string): Promise<Profile | null> {
    const url = `${apiUrl}user/profile`;

    const headers = new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': token,
    });

    const requestOptions: RequestInit = {
        method: 'GET',
        headers: headers,
    };

    try {
        const response = await fetch(url, requestOptions);

        if (response.status === 200) {
            const jsonData = await response.json();
            return Profile.fromJson(jsonData);
        } else {
            console.error(`Failed to get user details: ${response.statusText}`);
            return null;
        }
    } catch (error) {
        console.error('An error occurred while getting user details', error);
        return null;
    }
}

export async function updateProfile(profile: Profile, token: string): Promise<boolean> {
    const url = `${apiUrl}user/profile`;

    const headers = new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': token,
    });

    const body = JSON.stringify({
        name: profile.givenName,
        surname: profile.surname,
        phone: profile.telefonnummer,
        city: profile.wohnort,
        hausnummer: profile.hausnummer,
        strasse: profile.strasse,
        plz: profile.postleitzahl,
        role: profile.rolle
    });

    const requestOptions: RequestInit = {
        method: 'POST',
        headers: headers,
        body: body
    };

    try {
        const response = await fetch(url, requestOptions);

        if (response.status === 200) {
            const jsonData = await response.json();
            return jsonData.success === true;
        } else {
            console.error(`Failed to update user profile: ${response.statusText}`);
            return false;
        }
    } catch (error) {
        console.error('An error occurred while updating user profile', error);
        return false;
    }
}

export async function getUserDetails(uid: number, token: string): Promise<Map<string, any> | null> {
    const url = `${apiUrl}user/profile/${uid}`;

    const headers = new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': token,
    });

    const requestOptions: RequestInit = {
        method: 'GET',
        headers: headers,
    };

    try {
        const response = await fetch(url, requestOptions);

        if (response.status === 200) {
            const jsonData = await response.json();
            return new Map(Object.entries(jsonData));
        } else {
            alert(`There is no information about this user.`);
            return null;
        }
    } catch (error) {
        console.error('An error occurred while fetching user details', error);
        return null;
    }
}

export async function getEmailOrName(uid: number, token: string): Promise<string | null> {
    const url = `${apiUrl}user/${uid}`;

    const headers = new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': token,
    });

    const requestOptions: RequestInit = {
        method: 'GET',
        headers: headers,
    };

    try {
        const response = await fetch(url, requestOptions);

        if (response.status === 200) {
            const resultString = await response.text();
            return resultString;
        } else {
            alert(`There is no information about this user.`);
            return null;
        }
    } catch (error) {
        console.error('An error occurred while fetching user details', error);
        return null;
    }
}

export async function getUseChainID(uid: number, token: string): Promise<string | null> {
    const url = `${apiUrl}user/chain/${uid}`;

    const headers = new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': token,
    });

    const requestOptions: RequestInit = {
        method: 'GET',
        headers: headers,
    };

    try {
        const response = await fetch(url, requestOptions);

        if (response.status === 200) {
            const resultString = await response.text();
            return resultString;
        } else {
            alert(`There is no information about this user.`);
            return null;
        }
    } catch (error) {
        console.error('An error occurred while fetching user details', error);
        return null;
    }
}


export async function addDocument(did: number, uid: number, token: string): Promise<String> {
    const url = `${apiUrl}user/document/add/${did}/${uid}`;

    const headers = new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': token,
    });

    const requestOptions: RequestInit = {
        method: 'POST',
        headers: headers,
    };

    try {
        const response = await fetch(url, requestOptions);

        if (response.status === 200) {
            const jsonData = await response.json();
            return jsonData.documentTaid;
        } else {
            console.error(`Failed to add document: ${response.statusText}`);
            return "";
        }
    } catch (error) {
        console.error('An error occurred while adding the document', error);
        return "";
    }
}

export async function postRegister(email: string, password: string, onTokenReceived: (token: string) => void): Promise<boolean> {
    const url = `${apiUrl}user/auth`;
    const salt = 'UVocjgjgXg8P7zIsC93kKlRU8sPbTBhsAMFLnLUPDRYFIWAk';
    const saltedPassword = utf8.parse(salt + password);
    const password1 = sha256(saltedPassword).toString();
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    });

    const requestOptions: RequestInit = {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify({
            email: email,
            password: password1,
        }),
    };

    try {
        const response = await fetch(url, requestOptions);

        if (response.status === 200) {
            const jsonData = await response.json();
            onTokenReceived(jsonData["token"]);
            return true;
        } else {
            alert('Wrong password or e-mail!');
            return false;
        }
    } catch (error) {
        console.error('An error occurred while trying to register', error);
        return false;
    }
}

export async function deleteDocument(document: Document, token: string): Promise<boolean> {
    const url = `${apiUrl}user/document/${document.did}`;

    const headers = new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': token,
    });

    const requestOptions: RequestInit = {
        method: 'DELETE',
        headers: headers,
    };

    try {
        const response = await fetch(url, requestOptions);

        if (response.status === 200) {
            const jsonData = await response.json();
            return jsonData.success === true;
        } else {
            console.error(`Failed to delete document: ${response.statusText}`);
            return false;
        }
    } catch (error) {
        console.error('An error occurred while deleting the document', error);
        return false;
    }
}





