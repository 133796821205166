import React, {useContext, useState} from "react";
import { fetchLoginPassword } from "../utils/RequestApi";
import {CryptIDContext} from "../context/CryptIDContext";
import {AuthContext} from "../context/AuthTokenContext";

import tum_logo from '../assets/tum_logo.png';
import catena_logo from '../assets/catena_logo.png';

type LoginProps = {
    navigateTo: (page: 'main' | 'login' | 'register' | 'home') => void;
};

const Login: React.FC<LoginProps> = ({ navigateTo }) => {
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const cryptID = useContext(CryptIDContext);
    const {  setToken } = React.useContext(AuthContext);

    const handleLogin = async () => {
        if(email === '' || password === '') {
            alert("Please enter your credentials!");
            return;
        }
        try {
            const isLoggedIn = await fetchLoginPassword(email.toLowerCase(), password, cryptID, setToken);
            if (isLoggedIn) {
                navigateTo('home');
            }
        } catch (error) {
            alert("Login failed! Please check your credentials.");
        }
    };
    return (
        <div className="login-container">
            <div className="login-logo-container">
                <div className="login-logo-row">
                    <img src={tum_logo} alt="tum" className="login-logo-big"/>
                </div>
                <div className="login-logo-row">
                    <span className="digital-contracts">Digital Contracts</span>
                </div>
                <div className="login-logo-row">
                    <span className="powered-by">powered by</span>
                </div>
                <div className="login-logo-row">
                    <img src={catena_logo} alt="catena" className="login-logo"/>
                    <span className="catena">CATENA</span>
                </div>
            </div>
            <div className="login-logo-row">
                <input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
            </div>
            <div className="login-logo-row">
                <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
            </div>
            <div className="login-logo-row">
                <button className="login-button" onClick={handleLogin}>Login</button>
            </div>
            <div className="register-link-container">
                <span className="register-link" onClick={() => navigateTo('register')}>Don't have an account? Register here.</span>
            </div>
        </div>
    );
}



export default Login;
