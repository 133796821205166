type DocumentJSON = {
    did: string;
    uid: string;
    name: string;
    password: string;
    chain_document_TA_id: string;
};

export class Document {
    did: number;
    uid: number;
    name: string;
    password: string;
    chainId: string;

    constructor({
                    did = -1,
                    uid,
                    name,
                    password,
                    chainId,
                }: {
        did?: number;
        uid: number;
        name: string;
        password: string;
        chainId: string;
    }) {
        this.did = did;
        this.uid = uid;
        this.name = name;
        this.password = password;
        this.chainId = chainId;
    }

    static fromJson(json: DocumentJSON): Document {
        return new Document({
            did: parseInt(json.did, 10) || -1,
            uid: parseInt(json.uid, 10) || -1,
            name: json.name,
            password: json.password,
            chainId: json.chain_document_TA_id,
        });
    }

    static fromList(jsonList: DocumentJSON[] | null): Document[] {
        if (!jsonList) return [];
        return jsonList.map((e) => Document.fromJson(e));
    }

    toJson(): DocumentJSON {
        return {
            did: this.did.toString(),
            uid: this.uid.toString(),
            name: this.name,
            password: this.password,
            chain_document_TA_id: this.chainId,
        };
    }

    toString(): string {
        return `DID: ${this.did},\nUID: ${this.uid}, \nName: ${this.name}`;
    }
}
