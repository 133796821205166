import React, {useContext, useEffect, useState} from 'react';
import Login from './components/Login';
import Register from './components/Register';
import Home from './components/Home';
import tum_logo from './assets/tum_logo.png';
import './App.css';
import {CryptID, getCryptID} from './utils/CryptId';
import {CryptIDContext} from './context/CryptIDContext';
import catena_logo from "./assets/catena_logo.png";
import Typewriter from 'typewriter-effect';
import {fetchLoginCrypt} from "./utils/RequestApi";
import {AuthContext} from "./context/AuthTokenContext";

const App: React.FC = () => {
    const [cryptID, setCryptID] = useState<CryptID | null>(null);
    const [page, setPage] = useState<'main' | 'login' | 'register' | 'home'>('main');
    const { token, setToken } = useContext(AuthContext);

    const navigateTo = (page: 'main' | 'login' | 'register' | 'home') => {
        setPage(page);
    };

    useEffect(() => {
        const initializeCryptID = async () => {
            const id = await getCryptID("private5.pem", "public5.pem");
            setCryptID(id);
        };
        initializeCryptID();
    }, [setCryptID]);


    useEffect(() => {
        if (page === 'main' && cryptID) {
            (async () => {
                try {
                    const fetchedToken = await fetchLoginCrypt(cryptID);
                    if (fetchedToken) {
                        setToken(fetchedToken);
                        navigateTo('home');
                    }
                } catch (error) {
                    console.error("Error during fetchLoginCrypt:", error);
                }
            })();
        }
    }, [cryptID, page, setToken, token]);


    return (
        <CryptIDContext.Provider value={cryptID}>
            <div className={`App ${page === 'home' ? 'homeBackground' : page === 'login' || page === 'register' || page === 'main' ? 'loginBackground' : ''}`}>
                {page === 'main' && (
                    <div className="login-container">
                        <div className="login-logo-container">
                            <div className="login-logo-row">
                                <img src={tum_logo} alt="tum" className="login-logo-big"/>
                            </div>
                            <div className="login-logo-row">
                                <span className="digital-contracts">Digital Contracts</span>
                            </div>
                            <div className="login-logo-row">
                                <span className="powered-by">powered by</span>
                            </div>
                            <div className="login-logo-row">
                                <img src={catena_logo} alt="catena" className="login-logo"/>
                                <span className="catena">CATENA</span>
                            </div>
                        </div>
                        <div className="welcome-message">
                            <Typewriter options={{autoStart: true, loop: true, delay: 80, strings: ["Welcome!", "Willkommen!", "Bienvenido!", "Benvenuto!", "Witamy!", "Accueillir!", "Vítejte!", "Huānyíng!"]}}/>
                        </div>
                        <div className="login-logo-row">
                            <button className="login-button" onClick={() => navigateTo('login')}>Login</button>
                            <button className="login-button" onClick={() => navigateTo('register')}>Register</button>
                        </div>
                    </div>
                )}

                {page === 'login' && (
                    <div>
                        <Login navigateTo={navigateTo} />
                    </div>
                )}

                {page === 'register' && (
                    <div>
                        <Register navigateTo={navigateTo} />
                    </div>
                )}
                {page === 'home' && <Home navigateTo={navigateTo} />}
            </div>
        </CryptIDContext.Provider>
);
}
export default App;
