import React, {useState, FC, ReactNode, useMemo} from 'react';
import { AuthContext } from '../context/AuthTokenContext';

interface AuthProviderProps {
    children: ReactNode;
}

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
    const [token, setToken] = useState<string | null>(null);

    const contextValue = useMemo(() => {
        return { token, setToken };
    }, [token]);

    return (
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    );
};
