import React, {useContext, useEffect, useState} from 'react';
import {FiArrowLeft} from 'react-icons/fi';
import {User} from "../types/User";
import {UserProfile} from "../components/UserProfile";
import {getUserDetails, getUsers} from "../utils/RequestApi";
import {AuthContext} from "../context/AuthTokenContext";
import {Profile} from '../types/Profile';

interface UserListProps {
    onClose: () => void;
    showSendButton?: boolean;
    onSendClick?: (uid: number) => void;
}

export const UserList: React.FC<UserListProps> = ({onClose, showSendButton, onSendClick}) => {
    const [users, setUsers] = useState<User[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const {token} = useContext(AuthContext);
    const [selectedProfile, setSelectedProfile] = useState<Profile | null>(null);
    const [showUserProfile, setShowUserProfile] = useState(false);

    const handleUserClick = async (uid: number) => {
        if (!token) return;
        try {
            const userDetails = await getUserDetails(uid, token);
            console.log(userDetails);
            if (userDetails) {
                const profile = Profile.fromMap(userDetails);
                console.log('Selected profile:', profile);
                setSelectedProfile(profile);
                setShowUserProfile(true);
            }
        } catch (error) {
            console.error('An error occurred while fetching user details:', error);
        }
    };
    const handleCloseUserProfile = () => {
        setShowUserProfile(false);
    };
    const Avatar = ({user}: { user: User }) => {
        const getInitials = () => {
            if (!user) return '??';
            return `${user.email.charAt(0)}`;
        };

        return (
            <div className="small-avatar" style={{backgroundColor: "blue"}}>
                {getInitials()}
            </div>
        );
    };

    useEffect(() => {
        const fetchData = async () => {
            if (!token) return;
            try {
                const fetchedUsers: User[] = await getUsers(token);
                setUsers(fetchedUsers);
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };
        fetchData();
    }, [token]);

    const filteredUsers = users
        .filter(user => user.email.toLowerCase().includes(searchTerm.toLowerCase()))
        .sort((a, b) => (a.uid > b.uid ? 1 : -1));


    return (
        <div>
            {showUserProfile && selectedProfile && (
                <div className="strong-overlay">
                <div className="user-profile">
                    <UserProfile
                        profile={selectedProfile}
                        onClose={handleCloseUserProfile}
                        isEditable={false}
                    />
                </div>
                </div>
            )}
            <div className="user-list">
                <div className="nav-bar">
                    <FiArrowLeft className="icon back-icon" onClick={onClose}/>
                    <input
                        className="search-input"
                        type="text"
                        placeholder="Search for users..."
                        value={searchTerm}
                        onChange={e => setSearchTerm(e.target.value)}
                    />
                </div>
                <div className="user-list-content">
                    {filteredUsers.map(user => (
                        <div
                            key={user.uid}
                            className="user-item"
                            onClick={() => handleUserClick(user.uid)}
                        >
                            <Avatar user={user}/>
                            UID: {user.uid} E-Mail: {user.email}
                            {showSendButton && (
                                <button
                                    className="send-button"
                                    style={{backgroundColor: 'darkgrey'}}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        if (onSendClick) {
                                            onSendClick(user.uid);
                                        }
                                    }}
                                >
                                    Send
                                </button>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default UserList;
