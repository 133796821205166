import forge from "node-forge";

export class CryptID {
    public: string;
    signed: string;
    pair: forge.pki.KeyPair;

    constructor({ public: publicParam, signed, pair }: { public: string; signed: string; pair: forge.pki.KeyPair; }) {
        this.public = publicParam;
        this.signed = signed;
        this.pair = pair;
    }

    toJson() {
        const privateKey = this.pair.privateKey as forge.pki.rsa.PrivateKey; // Cast to the correct type
        const md = forge.md.sha256.create();
        const r = (Date.now() / 1000).toFixed();
        md.update(r);
        const signer = forge.md.sha256.create();
        signer.update(r);
        const signature = privateKey.sign(signer);

        return {
            public: this.public,
            signed: this.signed,
            r,
            r_sign: forge.util.encode64(signature),
        };
    }
}

async function createRSAKeys() {
    return new Promise<forge.pki.KeyPair>((resolve) => {
        forge.pki.rsa.generateKeyPair({ bits: 2048 }, (err: any, keypair: any) => {
            if (err) {
                throw err;
            }
            resolve(keypair);
        });
    });
}

function writeMemory(content: string, path: string): Promise<void> {
    return new Promise<void>((resolve) => {
        localStorage.setItem(path, content);
        resolve();
    });
}

function readMemory(path: string): Promise<string | null> {
    return new Promise<string | null>((resolve) => {
        const contents = localStorage.getItem(path);
        resolve(contents);
    });
}


export async function getCryptID(privatePath: string, publicPath: string): Promise<CryptID> {
    let privateKeyString = await readMemory(privatePath);
    let publicKeyString = await readMemory(publicPath);

    let pair: forge.pki.KeyPair;

    if (privateKeyString === null || publicKeyString === null) {
        console.log("CREATED");
        pair = await createRSAKeys();
        publicKeyString = forge.pki.publicKeyToPem(pair.publicKey);
        privateKeyString = forge.pki.privateKeyToPem(pair.privateKey);
        await writeMemory(privateKeyString, privatePath);
        await writeMemory(publicKeyString, publicPath);
    } else {
        console.log("READ");
        pair = {
            privateKey: forge.pki.privateKeyFromPem(privateKeyString),
            publicKey: forge.pki.publicKeyFromPem(publicKeyString),
        };
    }

    const privateKey = pair.privateKey as forge.pki.rsa.PrivateKey; // Cast to the correct type
    const md = forge.md.sha256.create();
    md.update(publicKeyString);
    const signer = forge.md.sha256.create();
    signer.update(publicKeyString);
    const signature = privateKey.sign(signer);
    const signed = forge.util.encode64(signature);
    const publicParam = forge.util.encode64(publicKeyString);

    return new CryptID({ public: publicParam, signed, pair });
}


