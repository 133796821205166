import {Document} from '../types/Document';
import {
    addDocument,
    deleteDocument,
    getDecryptedDocument,
    getDocument,
    getEmailOrName,
    getUseChainID
} from "../utils/RequestApi";
import {useContext, useEffect, useRef, useState} from "react";
import {AuthContext} from "../context/AuthTokenContext";
import * as pdfjsLib from 'pdfjs-dist';
import {UserList} from './UserList';
import {FaTrash} from 'react-icons/fa';
import { AiOutlineClose } from 'react-icons/ai';
import { IoIosInformationCircleOutline } from 'react-icons/io';

export const DocumentTile: React.FC<{
    document: Document;
    onDelete: (did: number) => void;
}> = ({document, onDelete}) => {
    const {token} = useContext(AuthContext);
    const [showPDFFullscreen, setShowPDFFullscreen] = useState(false);
    const fileType = document.name.split('.').pop();
    const [numPages, setNumPages] = useState<number>(0);
    const canvasRefs = useRef<HTMLCanvasElement[]>([]);
    const [pdfDocument, setPdfDocument] = useState<pdfjsLib.PDFDocumentProxy | null>(null);
    const [downloading, setDownloading] = useState(false);
    const [showUserList, setShowUserList] = useState(false);
    const [showDownloadMessage, setShowDownloadMessage] = useState(false);
    const [fileExtension, setFileExtension] = useState('');
    const [owner, setOwner] = useState<string | null>(null);
    const [chainID, setChainID] = useState<string | null>(null);
    const [showInfo, setShowInfo] = useState(false);

    let logoSrc = '';
    const fetchOwner = async () => {
        if(!token) return;
        const result = await getEmailOrName(document.uid, token);
        if (result) {
            setOwner(result.substring(1, result.length - 2));
        }
    };
    fetchOwner();
    const toggleUserList = (show: boolean) => {
        setShowUserList(show);
        if (show) {
            window.document.body.style.overflow = 'hidden';
        } else {
            window.document.body.style.overflow = 'auto';
        }
    };

    const handleInfoHover = async () => {
        if(!token) return;
        const fetchedChainID = await getUseChainID(document.uid, token);
        setChainID(fetchedChainID);
        setShowInfo(true);
    };
    const handleInfoLeave = () => {
        setShowInfo(false);
    };

    switch (fileType) {
        case 'pdf':
            logoSrc = process.env.PUBLIC_URL + '/assets/pdf_logo.jpg';
            break;
        case 'docx':
            logoSrc = process.env.PUBLIC_URL + '/assets/docx_logo.jpg';
            break;
        case 'doc':
            logoSrc = process.env.PUBLIC_URL + '/assets/docx_logo.jpg';
            break;
        case 'png':
            logoSrc = process.env.PUBLIC_URL + '/assets/picture_logo.jpg';
            break;
        case 'jpg':
            logoSrc = process.env.PUBLIC_URL + '/assets/picture_logo.jpg';
            break;
        case 'jpeg':
            logoSrc = process.env.PUBLIC_URL + '/assets/picture_logo.jpg';
            break;
        default:
            logoSrc = process.env.PUBLIC_URL + '/assets/unknown_logo.jpg';
    }
    const handleDownload = async () => {
        if (!token) return;
        setDownloading(true);
        window.document.body.classList.add('downloading');
        try {
            let documentBytes;
            if (fileType === 'pdf') {
                documentBytes = await getDecryptedDocument(document.did, token);
            } else {
                documentBytes = await getDocument(document.did, token);
            }
            const blob = new Blob([documentBytes], {type: `application/${fileType}`});
            const link = window.document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = document.name;
            link.click();

        } catch (error) {
            console.error("Error downloading the file:", error);
        }
        setDownloading(false);
        window.document.body.classList.remove('downloading');
    };

    const handleClick = async () => {
        if (!token) return;
        setDownloading(true);
        if(showInfo) setShowInfo(false);
        window.document.body.classList.add('downloading');
        pdfjsLib.GlobalWorkerOptions.workerSrc = "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.9.179/pdf.worker.js";
        try {
            const documentBytes = await getDocument(document.did, token);
            if (fileType === 'pdf') {
                const loadingTask = pdfjsLib.getDocument({data: documentBytes, password: document.password});
                setShowPDFFullscreen(true);
                const pdf = await loadingTask.promise;
                setNumPages(pdf.numPages);
                setPdfDocument(pdf);
                for (let i = 1; i <= pdf.numPages; i++) {
                    const page = await pdf.getPage(i);
                    const scale = 1;
                    const viewport = page.getViewport({scale});
                    const canvas = canvasRefs.current[i - 1];
                    const context = canvas.getContext('2d');
                    if (!context) {
                        console.error("Unable to get canvas context");
                        continue;
                    }
                    canvas.height = viewport.height;
                    canvas.width = viewport.width;
                    const renderContext = {
                        canvasContext: context,
                        viewport: viewport
                    };
                    const renderTask = page.render(renderContext);
                    await renderTask.promise;
                }
            } else {
                if(fileType) {
                    setFileExtension(fileType);
                }
                setShowDownloadMessage(true);
            }
        } catch (error) {
            console.error("Error displaying the document:", error);
        }
        setDownloading(false);
        window.document.body.classList.remove('downloading');
    };

    return (
        <div>
            <div className="document-tile">
                <img src={logoSrc} alt={`${fileType} logo`} className="document-logo"/>
                <div className="document-info">
                    <h5>{document.name}</h5>
                    <p>Owner: {owner}</p>
                    <p>TA ID: {document.chainId}</p>
                </div>
                <div className="overlay" onClick={handleClick}>
                    {downloading ? (
                        <div className="spinner"></div>
                    ) : (
                        <>
                            <div
                                className="info-icon"
                                onMouseEnter={handleInfoHover}
                                onMouseLeave={handleInfoLeave}
                            >
                                <IoIosInformationCircleOutline />
                            </div>
                            <span>Open</span>
                            <button
                                className="delete-button"
                                onClick={async (e) => {
                                    e.stopPropagation();
                                    if (!token) return;
                                    const confirmDelete = window.confirm('Are you sure you want to delete this document?');
                                    if (confirmDelete) {
                                        const success = await deleteDocument(document, token);
                                        if (success) {
                                            alert('Document deleted successfully');
                                            onDelete(document.did);
                                        } else {
                                            alert('Failed to delete the document');
                                        }
                                    }
                                }}
                            >
                                <FaTrash/>
                            </button>
                        </>
                    )}
                </div>
            </div>
            {showInfo && <div className="info-tooltip">User ChainID: {chainID?.substring(1, chainID?.length - 2)}</div>}
            {showDownloadMessage && (
                <div className="pdf-fullscreen">
                    <div className="button-container">
                        {downloading ? (
                            <div></div>
                        ) : (
                            <button className="close-button" onClick={() => setShowDownloadMessage(false)}>    <AiOutlineClose />
                            </button>
                        )}
                        {downloading ? (
                            <button className="download-button" disabled>
                                <div className="spinner"></div>
                            </button>
                        ) : (
                            <button className="download-button" onClick={handleDownload}>Download</button>
                        )}
                        <button className="user-list-toggle" onClick={() => toggleUserList(true)}>Send to user</button>
                        {showUserList && (
                            <div className="user-overlay">
                                <UserList onClose={() => toggleUserList(false)} showSendButton={true}
                                          onSendClick={async (uid) => {
                                              if (!token) return;
                                              const success = await addDocument(document.did, uid, token);
                                              if (success && success != "") {
                                                  alert('Document added successfully with TA ID: ' + success);
                                              } else {
                                                  alert('Document is already shared with this user');
                                              }
                                          }}/>
                            </div>
                        )}
                        <div className="download-message">
                            Files with the extension .{fileExtension} cannot be shown. Please download!
                        </div>
                    </div>
                </div>
            )}
            {showPDFFullscreen && (
                <div className="pdf-fullscreen">
                    <div className="button-container">
                        {downloading ? (
                            <div></div>
                        ) : (
                            <button className="close-button" onClick={() => setShowPDFFullscreen(false)}>    <AiOutlineClose />
                            </button>
                        )}
                        {downloading ? (
                            <button className="download-button" disabled>
                                <div className="spinner"></div>
                            </button>
                        ) : (
                            <button className="download-button" onClick={handleDownload}>Download</button>
                        )}
                        <button className="user-list-toggle" onClick={() => toggleUserList(true)}>Send to user</button>
                        {showUserList && (
                            <div className="user-overlay">
                                <UserList onClose={() => toggleUserList(false)} showSendButton={true}
                                          onSendClick={async (uid) => {
                                              if (!token) return;
                                              const success = await addDocument(document.did, uid, token);
                                              if (success) {
                                                  alert('Document added successfully');
                                              } else {
                                                  alert('Document is already shared with this user');
                                              }
                                          }}/>
                            </div>
                        )}
                    </div>
                    {Array.from({length: numPages}).map((_, index) => (
                        <canvas key={index} className="pdf-page-canvas"
                                ref={el => canvasRefs.current[index] = el as HTMLCanvasElement}></canvas>
                    ))}
                </div>
            )}
        </div>
    );
};
